import Vue from 'vue';
import Component, { createDecorator } from 'vue-class-component';
import { Mixin, Mixins } from 'vue-mixin-decorator';
import { Emit, Model, Prop, Provide, Watch } from 'vue-property-decorator';

export { Vue, Component, Emit, Model, Prop, Provide, Watch, Mixin, Mixins };

/**
 * Shorthand decorator to a boolean property
 * @param default the default value (false)
 * @return PropertyDecorator | void
 */
export function Bool() {
	return (target, key) => {
		const options = { type: Boolean };

		createDecorator((componentOptions, k) => {
			(componentOptions.props || (componentOptions.props = {}))[k] = options;
		})(target, key);
	};
}

Component.registerHooks([
	'beforeRouteEnter',
	'beforeRouteUpdate',
	'beforeRouteLeave',
	'asyncData',
	'fetch',
	// "fetchOnServer",
	// "head",
	// "key",
	// "layout",
	// "loading",
	// "middleware",
	// "scrollToTop",
	// "transition",
	// "validate",
	'validations',
	// "watchQuery",
	// "meta",
]);
