import Vue, { VNode } from 'vue';
import { IClsOptimizationMixin } from '@/node_modules/@osp/design-system/components/mixins/cls-optimization-mixin';
import { Fragment } from '@/node_modules/@osp/design-system/components/FragmentComponent/FragmentComponent';
import { Bool, Component, Mixins, Prop } from '@/app-utils/decorators';
import { useServerContextStore } from '~/@api/store/serverContextApi';
import { clientInitServerSettings, useServerSettingsStore } from '~/@api/store/serverSettingsApi';
import { IntersectionComponentConfig } from '~/@api/store.types';
import { ClsOptimizationMixin } from '~/components/mixins/cls-optimization-mixin';
import { importDebounce, importLogger } from '~/app-utils/dynamic-imports';

@Component({
	mixins: [ClsOptimizationMixin],
	data() {
		return {
			clsPreparedComponents: {},
			clsUID: false,
		};
	},
})
export default class IntersectingComponent extends Mixins<Vue & IClsOptimizationMixin>(Vue) {
	private observer: IntersectionObserver = null;
	private isIntersecting = false;
	private isBot = false;
	private timeoutElement = null;
	private debouncedOnScroll = null;
	private intersectingComponentsSettings = [];

	@Prop()
	public preload: string;

	@Prop()
	public threshold: number;

	@Prop()
	public timeout: number;

	@Prop()
	public configId: string;

	@Prop({ default: false })
	public horizontal: boolean;

	@Bool()
	public disabled: boolean;

	@Prop()
	public clsComponentKeys: string[];

	get shouldRenderContent(): boolean {
		return this.isIntersecting || !!this.config.disabled || this.disabled || this.isBot;
	}

	get options(): IntersectionObserverInit {
		return {
			rootMargin: `${this.preload || this.config.rootMargin || '15%'}`,
			threshold: this.threshold || this.config.threshold || 0,
		};
	}

	get config(): IntersectionComponentConfig {
		if (this.disabled || this.isBot) {
			return {};
		}

		let configId = null;
		let component = null;

		if (this.configId) {
			configId = this.configId;
		} else if (this.isEmpty(this.$slots.default)) {
			configId = 'default';
		} else {
			if (Object.keys(this.$slots.default).length > 1) {
				importLogger().then(({ default: Logger }) => {
					Logger.info(
						'More than one child component found for intersecting-component (lazy loading). Using first for determining configuration',
					);
				});
			}

			component = this.getValidComponent(this.$slots.default[0]);
			configId = (component.componentOptions || component.asyncMeta).tag;
		}

		const containsClsComponent = !!component?.componentOptions?.listeners?.clsPreparationFinished;
		const componentTag = component ? (component.componentOptions || component.asyncMeta).tag : null;

		return {
			componentTag,
			timeout: this.timeout,
			containsClsComponent,
			...(this.intersectingComponentsSettings.find((item) => item.id === 'default') || {}),
			...(this.intersectingComponentsSettings.find((item) => item.id === configId) || {}),
		};
	}

	private getValidComponent(component: VNode) {
		if (
			['App', 'Layout', 'LazyHydrate'].includes(
				(component.componentOptions || (component as any).asyncMeta).tag,
			)
		) {
			if (this.isEmpty(component.componentOptions.children)) {
				importLogger().then(({ default: Logger }) => {
					Logger.info('Could not find valid component config for intersection config');
				});
			} else {
				return this.getValidComponent(component.componentOptions.children[0]);
			}
		}

		return component;
	}

	beforeCreate() {
		clientInitServerSettings(this.$store);
	}

	created() {
		this.loadIntersectingComponentsSettings();
		this.isBot = useServerContextStore(this.$store).state.userAgent.isBot;

		if (this.clsComponentKeys) {
			this.clsComponentKeys.forEach((key: string, index: number) => {
				Vue.set(this.clsPreparedComponents, key, index === 0);
			});
		}
	}

	mounted() {
		if (!this.shouldRenderContent) {
			if (this.config.onScroll) {
				// eslint-disable-next-line require-await
				Vue.nextTick(async () => {
					this.handleContentDimensions();
				});
			} else {
				this.createObserver();
			}
		}
	}

	destroyed() {
		this.destroyObserver();
	}

	onScroll() {
		if (this.debouncedOnScroll) {
			document.removeEventListener('scroll', this.debouncedOnScroll);
		}

		this.createObserver();
	}

	render(h) {
		let style = { width: '100%', height: this.horizontal ? '100%' : '2000px' };

		if (this.config.dummyStyle) {
			try {
				style = JSON.parse(this.config.dummyStyle);
			} catch (e) {
				importLogger().then(({ default: Logger }) => {
					Logger.warn('Found invalid default style JSON for intersection component', this.config);
				});
			}
		}

		if (this.shouldRenderContent) {
			this.$emit('rendering');
			return h(Fragment, this.$slots.default);
		}

		return h('div', { style });
	}

	loadIntersectingComponentsSettings() {
		this.intersectingComponentsSettings =
			useServerSettingsStore(this.$store).state.settings.intersectingComponents || [];
	}

	handleContentDimensions() {
		const isHigherThanViewport = (element: HTMLElement) => {
			if (element && element.offsetHeight < window.innerHeight) {
				this.isIntersecting = true;
			} else if (!this.debouncedOnScroll) {
				importDebounce().then(({ debounce }) => {
					this.debouncedOnScroll = debounce(this.onScroll, 250);
				});
			} else {
				document.addEventListener('scroll', this.debouncedOnScroll);
			}
		};
		// Special use case for e.g. cart page to load footer directly
		// First try to load new Vue specific page content (first selector)
		// and if nothing is found, check if we are on a legacy angular page
		let content: HTMLElement = document.querySelector("[class*='osp_master'] > main");

		if (!content) {
			content = document.querySelector("[class='l-content'][data-currency-iso-code]");
		}

		if (content.offsetHeight <= 0) {
			// A hacky way to wait until lazy loaded content inside our selector is ready, so that its height can be calculated
			// Otherwise height will be always 0
			new MutationObserver((_mutations, obs) => {
				if (content.offsetHeight) {
					isHigherThanViewport(content);
					obs.disconnect();
				}
			}).observe(content, {
				childList: true,
				subtree: true,
			});
		} else {
			isHigherThanViewport(content);
		}
	}

	createObserver() {
		if (this.observer == null) {
			this.observer = new IntersectionObserver((entries) => {
				this.isIntersecting = entries.some((entry) => entry.isIntersecting);

				if (this.isIntersecting) {
					this.destroyObserver();

					if (this.timeoutElement != null) {
						clearTimeout(this.timeoutElement);

						this.timeoutElement = null;
					}
				}
			}, this.options);

			this.observer.observe(this.$el);
		}
	}

	destroyObserver() {
		if (this.config.onScroll && this.debouncedOnScroll) {
			document.removeEventListener('scroll', this.debouncedOnScroll);
		}

		if (this.observer) {
			this.observer.disconnect();

			this.observer = null;
		}
	}

	isEmpty(value: Object): boolean {
		return (
			value == null ||
			(typeof value === 'object' && Object.keys(value).length === 0) ||
			(typeof value === 'string' && value.trim().length === 0)
		);
	}
}
