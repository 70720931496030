import Vue from 'vue';
import { importRunTask } from '../../assets/js/utilities/dynamicImports';

export interface ILcpOptimizationMixin {
	triggerInitialHydration: boolean;
	isFirstSpaPage: boolean;
	nuxtReady: boolean;
	userInteracted: boolean;
	previewImageOptimization: boolean;
	isMobileBreakpointRange: boolean;
	lcp: {
		optimizationDesktop: boolean;
	};

	deferOnNuxtReady(callback: Function, allowSSR: boolean | undefined): void;

	deferOnFirstUserInteraction(callback: Function, allowSSR?: boolean | undefined): void;
}

export const LcpOptimizationMixin = Vue.extend({
	data() {
		return {
			isFirstSpaPage: true,
			previewImageOptimization: true,
			userInteracted: !!this.$store?.state?.ux?.userInteracted,
			uiBlockedByOverlay: false,
			nuxtReady: !!this.$store?.state?.ux?.nuxtReady,
			lcp: {
				optimizationDesktop: false,
				watchers: {} as {
					[key: string]: any;
				},
			},
		};
	},
	computed: {
		triggerInitialHydration() {
			return !!this.$store?.state?.ux?.cmpChoiceExists || !!this.$store?.state?.ux?.userInteracted;
		},
	},
	created() {
		this._updateStatusFlags();
		this._updatePreviewImageOptimization();
	},
	beforeMount() {
		if (!this.userInteracted) {
			this._setOneTimeStoreWatcher('userInteracted', () => {
				this._updateStatusFlags();
				this._updatePreviewImageOptimization();
			});
		}

		if (!this.nuxtReady) {
			this._setOneTimeStoreWatcher('nuxtReady', () => {
				this._updateStatusFlags();
				this._updatePreviewImageOptimization();
			});
		}
	},
	mounted() {
		this.uiBlockedByOverlay = !this.$store?.state?.ux?.cmpChoiceExists;
	},
	beforeDestroy() {
		Object.values(this.lcp.watchers).forEach((unwatch: null | Function) => unwatch?.());
	},
	methods: {
		_updateStatusFlags() {
			this.isFirstSpaPage = this.isFirstSpaPageCheck();

			importRunTask().then(({ runTask }) => {
				runTask(() => {
					this.userInteracted =
						this.$store?.state?.ux?.userInteracted || (process.client && window?.scrollY > 0);
				});
				runTask(() => {
					this.nuxtReady = this.$store?.state?.ux?.nuxtReady;
				});
			});
		},
		isFirstSpaPageCheck() {
			return (
				process.server ||
				(this.$nuxt
					? !this.$nuxt?.context?.from || 'force-device' in this.$nuxt?.context?.from?.query
					: !document.referrer || !document.referrer?.includes(window?.location?.hostname))
			);
		},
		deferOnNuxtReady(callback: Function, allowSSR = false): void {
			if ((allowSSR && process?.server) || this.nuxtReady) {
				callback();
			} else if (this.$store?.state?.ux?.nuxtReady) {
				callback();
			} else {
				(window as any).onNuxtReady(() => callback());
			}
		},
		deferOnFirstUserInteraction(callback: Function, allowSSR = false): void {
			if ((allowSSR && process?.server) || this.userInteracted) {
				callback();
			} else if (this.$store?.state?.ux?.userInteracted) {
				callback();
			} else {
				this.$root.$on('firstUserInteraction', callback);
			}
		},
		_setOneTimeStoreWatcher(propertyName: string, callback: Function): void {
			if (!this.$store) return;

			const watcherName = `${propertyName}Watcher`;

			this.lcp.watchers[watcherName] = this.$watch(
				`$store.state.ux.${propertyName}`,
				(newValue: boolean) => {
					if (!newValue) return;

					// @ts-ignore
					this[propertyName] = true;

					callback();
					this.lcp.watchers[watcherName]?.();
				},
			);
		},
		_updatePreviewImageOptimization(): void {
			if (process.server) {
				this.previewImageOptimization = true;
				return;
			}

			importRunTask().then(({ runTask }) => {
				runTask(() => {
					this.previewImageOptimization =
						(this.BREAKPOINT.IS_MOBILE ||
							(this.lcp.optimizationDesktop && this.BREAKPOINT.IS_DESKTOP)) &&
						this.isFirstSpaPage &&
						!this.userInteracted;
				});
			});
		},
	},
});
