import { ClsOptimizationMixin as ClsOptimizationMixinBase } from '@/node_modules/@osp/design-system/components/mixins/cls-optimization-mixin';
import { stringify } from '@/node_modules/@osp/design-system/assets/js/utilities/stringify';
import { runTask } from '@/node_modules/@osp/design-system/assets/js/utilities/runTask';
import { clientInitClsStore, useClsStore } from '~/@api/store/clsApi';
import { useServerContextStore } from '~/@api/store/serverContextApi';

/**
 * Deprecated. Use ClsComponentMixin and ClsContainerMixin for new implementations instead
 * @deprecated
 */
export const ClsOptimizationMixin = {
	extends: ClsOptimizationMixinBase,
	data() {
		return {
			activateAllTrigger: false,
			clsStoreEmbedded: true,
		};
	},
	watch: {
		activateAllTrigger() {
			if (this.activateAllTrigger) {
				runTask(this.clsActivateAll);
			}
		},
		'$store.state.activateStateAllTrigger': {
			handler(newValue: boolean) {
				this.activateAllTrigger = newValue;
			},
		},
	},
	beforeCreate() {
		clientInitClsStore(this.$store);
	},
	computed: {
		clsConfig() {
			// use cls config from store if available in context, otherwise local fallback
			return (
				useClsStore(this.$store).state.config || {
					optimizationActive: true,
					allowFallback: true,
					fallbackTimerMs: 1500,
					fallbackTimerMsSpa: 2500,
					visibilityCheck: true,
					debugging: {
						active: false,
						outputTo: '',
					},
				}
			);
		},
		clsStoreOptimizationEnabled() {
			return useServerContextStore(this.$store).state.session.clsOptimizationEnabled;
		},
		resetStatesTrigger() {
			return useClsStore(this.$store).state.resetStateAllTrigger;
		},
	},
	methods: {
		clsStoreGetConfig() {
			return useClsStore(this.$store).state.config;
		},
		clsStoreClearStates() {
			const { api: clsApi, state: clsState } = useClsStore(this.$store);

			if (this.clsComponentKey in clsState.ssrRegister) {
				// ... otherwise clear the cls states if exist
				clsApi.clearClsStates(this.clsComponentKey);
			}
		},
		clsStoreGetSsrClsState() {
			const { state: clsState } = useClsStore(this.$store);

			if (this.clsComponentKey && this.clsComponentKey in clsState.ssrRegister) {
				this.clsPreparedComponents = Object.assign(
					this.clsPreparedComponents,
					clsState.ssrRegister[this.clsComponentKey],
				);

				this.clsDebug(
					`Fetch ssr states for ${this.clsComponentKey}: \n ${stringify(
						this.clsPreparedComponents,
					)} `,
				);
			}
		},
		clsStoreSaveSsrClsState() {
			const { api: clsApi, state: clsState } = useClsStore(this.$store);

			if (
				this.clsComponentKey &&
				Object.keys(this.clsPreparedComponents).length > 0 &&
				!(this.clsComponentKey in clsState.ssrRegister)
			) {
				this.clsDebug('Store CLS state for ' + this.clsComponentKey);
				clsApi.storeClsStates(this.clsComponentKey, this.clsPreparedComponents);
			}
		},
	},
};
